<template>
  <vx-card :title="pageTitle">
    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <filter-groups :model="queryStrings" :filter-groups="filterGroups" @update="getEntries"/>
        </div>
      </div>

      <div class="vx-col md:w-3/4 w-full">
        <vs-table max-items="10" pagination search stripe :data="options" :hoverFlat="true">
          <template #thead>
            <vs-th sort-key="institution_name">Institución</vs-th>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="device_type">Tipo de dispositivo</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template v-slot="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td><p class="font-medium">{{ record.institution.name }}</p></vs-td>
              <vs-td><p class="font-medium">{{ record.name }}</p></vs-td>
              <vs-td><p class="font-medium">{{ deviceTypeNameMapper[record.device_type] || "-" }}</p></vs-td>
              <vs-td>
                <div class="flex">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('update', 'menu'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="retrieveEntry(record.id)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      :disabled="record.in_production" size="large" :color="record.in_production ? 'dark' : 'success'"
                      type="flat" icon-pack="feather" icon="icon-send" @click="confirmCommit(record)" @click.stop
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import CoreClient from "@/utils/coreClient";
  import Notifier from "@/utils/notification";
  import {CommitEntryNotification} from "@/utils/notificationMixins";
  import FilterGroups from "@/components/filter-groups/FilterGroups.vue";

  const institutionsClient = new CoreClient("institutions");
  const menusClient = new CoreClient("menus");
  const notifier = new Notifier()

  export default {
    components: {FilterGroups},
    data(){
      return {
        filterGroups: [
          {
            name: "institution_id",
            label: "Institución",
            filters: [],
          },
          {
            name: "device_type",
            label: "Tipo de dispositivo",
            filters: [
              {label: "Escritorio", value: "desktop"},
              {label: "Móvil", value: "mobile"},
            ],
          }
        ],
        pageTitle: "Menús",
        queryStrings: {},
        options: [],
      }
    },

    computed: {
      deviceTypeNameMapper(){
        return {
          "mobile": "Móvil",
          "desktop": "Escritorio",
        };
      },

      institutionsMapper(){
        return {
          bank: "Banco",
          insurances: "Seguros",
          companies: "Empresas",
        }
      },
    },

    async created() {
      try{
        this.$vs.loading();

        const institutionsResponse = await institutionsClient.all();
        this.filterGroups[0].filters = institutionsResponse.data.map((institution) => {
          return { label: institution.name, value: institution.id };
        });

        await this.getEntries();
        this.$vs.loading.close();
      } catch (_) {
        this.$vs.loading.close();
        await notifier.notification('error');
      }
    },

    methods: {
      confirmCommit(entry){
        CommitEntryNotification.fire().then(async (result) => {
          if (result.value && entry.id){
            try{
              this.$vs.loading();
              await menusClient.commitMenu({pathParameters: {entryId: entry.id}});
              await notifier.notification("committed");
              this.$vs.loading.close();
              await this.getEntries();
            } catch(_) {
              this.$vs.loading.close();
              await notifier.alertMessage("error");
            }
          }
        })
      },

      createEntry(){
        this.$router.push({name: "create-menu"});
      },

      async getEntries(){
        const menusResponse = await menusClient.searchMenus({
          queryStrings: this.queryStrings,
        });
        this.options = menusResponse.data.results.map((entry) => {
          return {
            ...entry,
            institution_name: entry.institution.name
          }
        });
      },

      retrieveEntry(entryId) {
        this.$router.push({name: "menu", params: {id: entryId}});
      },
    }
  }
</script>

<style scoped>

</style>
